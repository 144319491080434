import { GatsbyImage, StaticImage } from "gatsby-plugin-image";
import * as React from "react"
import { Head } from "./head"
import * as styles from "./layout.module.scss";

import headerImg from '../../images/ut-logo.png';

export default function Layout({ children }) {
    return (

        <>
            <Head />

            <header className={styles.header}>
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col">
                            <div className={`text-center`}>
                                <img class="img-fluid" height={40} width={254} src={headerImg} alt="Urban Truant Logo" />
                                {/* <StaticImage height={40} placeholder="none" src={headerImg} alt="Urban Truant Logo" /> */}
                            </div>
                        </div>
                    </div>
                </div>
            </header>

            {children}

            <footer className={styles.footer}>

                <div className="container">

                    <div className="row">

                        <div className="col">
                            Urban Truant | 2022
                        </div>

                        <div className="col-auto">
                            <a href="mailto:enquire@urbantruant.com">enquire@urbantruant.com</a>
                        </div>

                        <div className="col-auto">
                            <a href="tel:02380458578">023 8045 8578</a>
                        </div>

                    </div>
                </div>

            </footer>

        </>

    )
}
