import React, { useEffect, useState } from "react"
import Layout from "../components/layout/layout"
import * as styles from "./index.module.scss"

let countTo = new Date("2022-03-31T00:00:00.000Z");

export default function Index() {

  const [int, setInt] = useState(null);
  const [rDay, setRDay] = useState(null)
  const [rHour, setRHour] = useState(null); //countdown 2 hours.
  const [rMin, setRMin] = useState(null);
  const [rSec, setRSec] = useState(null);

  useEffect(() => {

    load();

  }, [])

  function load() {
    clearInterval(int);

    var interval = setInterval(() => {
      var now = new Date();
      var diff = countTo - now;


      if (diff > 0) {
        // Time calcs
        var d = Math.floor(diff / (1000 * 60 * 60 * 24));
        var h = Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        var m = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
        var s = Math.floor((diff % (1000 * 60)) / 1000);

        setRDay(("0" + d).slice(-2))
        setRHour(("0" + h).slice(-2));
        setRMin(("0" + m).slice(-2));
        setRSec(("0" + s).slice(-2));

      } else {
        // DONE
      }

    }, 1000);

    setInt(interval);
  }

  return (
    <>
      <Layout>


        <section className={`${styles.section} ${styles.land}`}>
          <div className="container">
            <div className="row">
              <div className="col-12 col-lg-5">

                <div className="mb-4">
                  <h2>Our website is under construction</h2>
                  <h1>
                    Our team are working on some amazing updates! 
                  </h1>
                  <p>
                    Urban Truant offers an easy-to-use website design service to meet a variety of needs. All websites developed by Urban Truant are designed to be managed and edited by you – no need to contact anyone to upload a new photo, write a blog article or change some text on your home page!
                  </p>
                </div>

              </div>
              <div className="col-12 offset-lg-1 col-lg-6 d-flex align-items-center justify-content-center">

                {rDay ? (
                  <div className="">
                    <div className={styles.countdown}>

                      <div className={styles.block}>
                        <span className={styles.value}>{rDay}</span>
                        <span className={styles.label}>Days</span>
                      </div>

                      <div className={styles.block}>
                        <span className={styles.value}>{rHour}</span>
                        <span className={styles.label}>Hours</span>
                      </div>

                      <div className={styles.block}>
                        <span className={styles.value}>{rMin}</span>
                        <span className={styles.label}>Minutes</span>
                      </div>

                      <div className={styles.block}>
                        <span className={styles.value}>{rSec}</span>
                        <span className={styles.label}>Seconds</span>
                      </div>

                    </div>
                  </div>
                ) : ""}

              </div>
            </div>
          </div>
        </section>

      </Layout>
    </>

  )
}
